import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { IUser } from './authen';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class AuthenService {
  private currentUserSubject: BehaviorSubject<IUser>;
  public currentUser: Observable<IUser>;
  constructor(private http: HttpClient) {}

  userLogin(username: string, password: string) {
    return this.http
      .post<any>(
        `${environment.URL}/login`,
        {
          username,
          password,
        },
        httpOptions
      )
      .pipe(
        map((user) => {
          localStorage.setItem('user', JSON.stringify(user.data));
          return user;
        })
      );
  }
}
