import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AutologoutService {
  constructor(private router: Router, private ngZone: NgZone) {
    this.lastAction(Date.now());
    this.check();
    this.initListener();
    this.initInterval();
  }
  getLastAction() {
    return localStorage.getItem('lastAction');
  }

  lastAction(value) {
    localStorage.setItem('lastAction', JSON.stringify(value));
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    });
  }

  reset() {
    this.lastAction(Date.now());
  }

  check() {
    const now = Date.now();
    const timeLeft = parseInt(this.getLastAction()) + 30 * 60 * 1000;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;
    //this.isLoggedIn.subscribe(event => this.isLogin = event);
    this.ngZone.run(() => {
      if (isTimeout) {
        localStorage.removeItem('user');
        localStorage.removeItem('lastAction');
        setTimeout(() => {
          console.log(
            'Your Session Expired due to longer Inactivity, Login Again To Continue'
          );
        }, 10000);
        alert('กรุณาเข้าสู่ระบบใหม่อีกครั้ง');
        this.router.navigate(['login']);
      }
    });
  }
}
