<div class="h-100">
  <div class="h-100 w-100" style="position: absolute">
    <img
      src="../../assets/images/aot_logo_new.png"
      class="img-fluid"
      style="max-height: 100px; margin-left: 10px"
    />
    <img
      src="../../assets/images/login_background.png"
      class="img-fluid w-100"
      style="opacity: 0.5; max-height: 80%"
    />
    <!-- <div class="w-100 h-100" style="background-color: aqua;"></div> -->
  </div>
  <div class="container d-flex h-100">
    <div class="row align-self-center w-100">
      <div class="col-6 mx-auto">
        <div
          class="border border-dark px-3 pt-1 pt-1"
          style="background-color: white"
        >
          <img src="../../assets/images/aot_logo_new.png" class="img-fluid" />
          <p class="lead text-center">Dispatch Website</p>

          <form class="form-group" [formGroup]="loginForm">
            <div class="form-group">
              <label for="loginGroupInput1">Username</label>
              <input
                type="text"
                class="form-control"
                placeholder=""
                formControlName="username"
                id="username"
                (keyup.enter)="onKeyUpEvent($event, 'password')"
              />
              <div *ngIf="isSubmitted && loginFormControl.username.errors">
                <span
                  *ngIf="loginFormControl.username.errors.required"
                  class="text-danger"
                  >กรุณากรอกชื่อผู้ใช้</span
                >
                <!-- this.loginFormControl.username.errors.pattern -->
                <span
                  *ngIf="this.loginFormControl.username.errors.pattern"
                  class="text-danger"
                  >กรุณากรอกเฉพาะภาษาอังกฤษและตัวเลข</span
                >
              </div>
            </div>
            <div>
              <label for="loginGroupInput2">password</label>
              <input
                type="password"
                class="form-control"
                placeholder=""
                formControlName="password"
                id="password"
                (keyup.enter)="onKeyUpEvent($event, 'login')"
              />
              <div *ngIf="isSubmitted && loginFormControl.password.errors">
                <span
                  *ngIf="loginFormControl.password.errors.required"
                  class="text-danger"
                  >กรุณากรอกรหัสผ่าน</span
                >
              </div>
            </div>
          </form>

          <div class="form-group">
            <select id="inputState" class="form-control">
              <option selected>Computer Mode</option>
              <option>Tablet Mode</option>
            </select>
          </div>

          <div class="form-group mx-auto">
            <button
              class="btn btn-lg btn-block btn-secondary rounded"
              (click)="onSubmit()"
              style="background-color: #00bee2"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<jw-modal id="custom-modal-1">
  <div class="container">
    <h1>- {{ title }}</h1>
    <div style="height: 1px; background-color: black"></div>
    <div style="min-height: 100px">{{ modal_message }}</div>
  </div>
  <button
    class="btn btn-lg btn-block btn-secondary"
    (click)="closeModal('custom-modal-1')"
    style="
      background-color: #f0f0f7;
      color: #0b499c;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    "
  >
    ตกลง
  </button>
</jw-modal>
