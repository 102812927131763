import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reportvrp',
  templateUrl: './reportvrp.component.html',
  styleUrls: ['./reportvrp.component.css']
})
export class ReportvrpComponent implements OnInit {

  lang: any = "en"

  allUsers: any = [
    {id:"id1" , name:"name1" , username:"username1", email:"email1", phone:"phone1", address:"address1"},
    {id:"id2" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id3" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id4" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id5" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id6" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id7" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id8" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id9" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id10" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id11" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id12" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id13" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id14" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id15" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id16" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id17" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id18" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"},
    {id:"id19" , name:"name2" , username:"username2", email:"email2", phone:"phone2", address:"address2"}];

    dtOptions: DataTables.Settings = {};

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.dtOptions = {
      searching: false,
      ordering:  false,
      pageLength: 10,
      processing: true,
      lengthChange:false
    };
  }

  public openRoute(event) {
    this.router.navigate([event]);
  }

}
