<!-- header -->
<div class="container-fluid" style="background-color: #EFEDED;">
    <div class="row justify-content-between">
        <div>
            <img src="../../assets/images/aot_logo_new.png" class="img-fluid" style="max-height: 75px;margin-left: 10px;"/>
        </div>
        <div class="align-items-center" style="display: flex;">
            <div id="" style="margin-right: 10px;">{{userid}}</div>
            <div id="firstname" style="margin-right: 10px;">{{firstname}}</div>
            <div is="lastname" style="margin-right: 10px;">{{lastname}}</div>
            <div style="margin-right: 10px;" (click)="openModal('modal_logout')">
                <img src="../../assets/images/icon_logout.png" class="img-fluid" style="max-height: 40px;"/>
            </div>
        </div>
    </div>
</div>

<!-- menu -->
<div class="container-fluid">
    <div class="align-items-center" style="display: flex;">
        <img src="../../assets/images/icon_back.png" class="img-fluid" style="max-height: 45px;margin: 20px;" (click)="openRoute('home')"/>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('getjob')">รับงาน </button>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('finishjob')">เสร็จงาน </button>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('reportlss')">รายงาน LSS </button>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('reportvip')">รายงาน VIP </button>
        <button class="btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;margin: 10px;" >รายงานการซ่อม </button>
    </div>
</div>

<div class="col align-items-end" style="display: flex;padding: 0px;">
    <div class="col-9" style="padding: 0px;">
        <div class="col" style="display: flex;">
            <div class="col-5 align-items-center" style="display: flex;padding: 0px;">
                <div class="col-4">พนักงานขับรถ :</div>
                <div class="col-8">
                    <input type="text" class="form-control" name="emp_no" id="emp_no" />
                </div>
            </div>
            <div class="col-5 align-items-center" style="display: flex;padding: 0px;">
                <div class="col-4">ทะเบียนรถ : </div>
                <div class="col-8">
                    <input type="text" class="form-control" name="car_no" id="car_no"/>
                </div>
            </div>
            <div class="align-items-center">
                <select class="form-control" name="cars_model" id="cars_model" style="width: 150px;">
                    <option value="def">ทุกรุ่นรถ</option>
                    <option [value]="option.veh_brand_code" *ngFor="let option of vehInfo; index as i" >
                        {{ option.veh_brand_code }}
                    </option>
                </select>
            </div>
        </div>

        <div class="col container" style="display: flex;margin-top: 20px;">
            <div class="col-5 align-items-center" style="display: flex;padding: 0px;">
                <div class="col-4">QR code ใบงาน : </div>
                <div class="col-8">
                    <input type="text" class="form-control" name="qr_no" id="qr_no"/>
                </div>
            </div>
           <!--- <div class="col-5 align-items-center" style="display: flex;padding: 0px;">
                <div class="col-4">รหัสใบงาน : </div>
                <div class="col-8">
                    <input type="text" class="form-control" name="job_no" id="job_no"/>
                </div>
            </div>-->
            <div class="align-items-center">
                <select class="form-control" name="job_status" id="job_status" style="width: 150px;">
                    <option value="">สถานะใบงาน</option>
                    <option value="0">สร้างตั๋วมาใหม่</option>
                    <option value="1">คนขับกดเริ่มงาน</option>
                    <option value="2">ออกเดินทาง</option>
                    <option value="3">ถึงอู่</option>
                    <option value="8">ถึงเขตสุวรรณภูมิ</option>
                    <option value="9">คนขับจบงาน/ถึงcp</option>
                    <option value="10">ปิดงาน</option>
                    <option value="13">Assingงานแล้ว</option>
                    <option value="14">เสร็จงาน</option>              
                </select>
            </div>
        </div>
    </div>

    <div class="col-3" style="display: flex;padding-bottom: 5px;">
        <button class="col-6 btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;" (click)="getReport();">ค้นหา</button>
    </div>
</div>

<div class="container-fluid">
    <div class="col" style="margin-top: 10px;">แสดงรายการที่รับรถ 24 ชั่วโมง</div>
</div>

<div class="container-fluid">
    <div class="col" style="margin-top: 20px;">
        <table id="weeklytable" class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions">
            <thead>
            <tr>
                <th>No.</th>
                <th>เวลาที่รับงาน/พนักงาน Dispatcher</th>
                <th>เวลาที่เสร็จงาน/พนักงาน Dispatcher</th>
                <th>สถานะปัจจุบัน</th>
                <th>รหัสพนักงาน</th>
                <th>พนักงานขับรถ</th>
                <th>รุ่นรถ</th>
                <th>หมายเลขรถ</th>
                <th>ทะเบียน</th>
                <th>QR Code ใบงาน</th>
                <th>Job No.</th>
                <th>ประเภทงาน</th>
                <th>Pickup Datetime</th>
                <!-- <th>ต้นทาง</th> -->
                <th>สถานที่</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of reportData">
                
            </tr>
            </tbody>
        </table>
        <div *ngIf="totalpage > 0">
            <nav aria-label="...">
                <ul class="pagination pagination-sm justify-content-end flex-wrap">
                  <li *ngIf="prepage != null" class="page-item">
                    <span class="page-link" (click)="setpage(prepage)">Previous</span>
                  </li>
                  <li *ngFor="let p of allpage" [ngClass]="p == page ? 'page-item active' : 'page-item'">
                      <a class="page-link" (click)="setpage(p)">{{p}}</a>
                  </li>
                  <li *ngIf="lastpage != null" class="page-item">
                    <a class="page-link">...</a>
                  </li>
                  <li *ngIf="lastpage != null" class="page-item">
                    <a class="page-link" (click)="setpage(lastpage)">{{lastpage}}</a>
                  </li>
                  <li *ngIf="nextpage != null" class="page-item">
                    <a class="page-link" (click)="setpage(nextpage)">Next</a>
                  </li>
                </ul>
            </nav>
        </div>
    </div>
</div>

<!-- logout dialog  -->
<jw-modal id="modal_logout">
    <div class="container">
      <h1>Dispatch Website</h1>
      <div style="height: 1px;background-color: black;"></div>
      <div style="min-height: 100px;">ออกจากระบบ</div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <button class="col btn btn-lg btn-block" (click)="signOut();" style="background-color: #F0F0F7;color: #0B499C;border-radius: 0px;border-bottom-left-radius: 10px;">
                ตกลง
            </button>
            <button class="col btn btn-lg btn-block" (click)="closeModal('modal_logout');" style="background-color: #EDDBDB;color: #F06464;;border-radius: 0px;border-bottom-right-radius: 10px;margin: 0px;">
              ยกเลิก
            </button>
        </div>
    </div>
</jw-modal>