import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportRepository } from '../repository/report';
import { AutologoutService } from '../services/autologout/autologout.service';
import { ModalService } from '../_modal';

@Component({
  selector: 'app-reportgetreturncar',
  templateUrl: './reportgetreturncar.component.html',
  styleUrls: ['./reportgetreturncar.component.css'],
})
export class ReportgetreturncarComponent implements OnInit {
  lang: any = 'en';
  reportData: any;

  dtOptions: DataTables.Settings = {};

  userid: any;
  firstname: any;
  lastname: any;
  accessToken: any;

  error_message = '';

  constructor(
    private router: Router,
    private modalService: ModalService,
    private reportRepo: ReportRepository,
    private autoLogoutService: AutologoutService
  ) {}

  ngOnInit(): void {
    this.dtOptions = {
      searching: false,
      ordering: false,
      pageLength: 10,
      processing: true,
      lengthChange: false,
    };

    $('#weeklytable').DataTable().destroy();

    try {
      var currentUser = JSON.parse(localStorage.getItem('user'));

      console.info(currentUser);

      this.userid = currentUser.account.user_id;
      this.firstname = currentUser.profile.firstname_th;
      this.lastname = currentUser.profile.lastname_th;

      this.accessToken = currentUser.accessToken;
    } catch (error) {}
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getReport();
    }, 0);
  }

  public openRoute(event) {
    this.router.navigate([event]);
  }

  public openModal(id: string) {
    this.modalService.open(id);
  }

  public closeModal(id: string) {
    this.modalService.close(id);
  }

  public signOut() {
    localStorage.setItem('user', null);
    this.openRoute('login');
  }

  async getReport() {
    this.reportData = null;
    let name = (document.getElementById('emp_no') as HTMLInputElement).value;
    let car = (document.getElementById('car_no') as HTMLInputElement).value;
    let body = {};
    if (name != '' && car != '') {
      body = {
        license_plate_no: car,
        driver: name,
      };
    } else if (name == '' && car != '') {
      body = {
        license_plate_no: car,
      };
    } else if (name != '' && car == '') {
      body = {
        driver: name,
      };
    }
    console.log(body);
    try {
      var data: any = await this.reportRepo.getReportGetReturnCar(
        this.accessToken,
        body
      );
      this.error_message = '';
      if (data.code == 200) {
        this.reportData = data.data.assignVehicle;

        $('#weeklytable').DataTable().destroy().clear;

        var index = 0;
        var getDatetime = (date) => this.getDatetime(date);
        var checkNullString = (string) => this.checkNullString(string);
        var t = $('#weeklytable').DataTable({
          searching: false,
          ordering: false,
          pageLength: 10,
          processing: true,
          lengthChange: false,
          data: this.reportData,
          columns: [
            {
              title: 'No.',
              data: null,
              render: function (data, type, row) {
                index = index + 1;
                return index.toString();
              },
            },
            { title: 'รหัส', data: 'dispatch_id' },
            {
              title: 'พนักงานขับรถ',
              data: null,
              render: function (data, type, row) {
                return (
                  row.driver_firstname_th +
                  ' ' +
                  row.driver_lastname_th +
                  '<br>' +
                  row.driver_phone_number +
                  ''
                );
              },
            },
            { title: 'รุ่นรถ', data: 'veh_model_name' },
            { title: 'ทะเบียน', data: 'veh_info_license_plate_no' },
            { title: 'กม.เริ่มต้น', data: 'dispatch_km' },
            {
              title: 'เวลารับรถ/พนักงาน Dispatcher',
              data: null,
              render: function (data, type, row) {
                return (
                  getDatetime(row.dispatch_time) +
                  '<br>' +
                  checkNullString(row.emp_send_firstname_th) +
                  ' ' +
                  checkNullString(row.emp_send_lastname_th) +
                  '<br>' +
                  checkNullString(row.emp_send_phone_number) +
                  ''
                );
              },
            },
            { title: 'กม.สิ้นสุด', data: 'return_km' },
            {
              title: 'เวลาคืนรถ/พนักงาน Dispatcher',
              data: null,
              render: function (data, type, row) {
                return (
                  getDatetime(row.return_time) +
                  '<br>' +
                  checkNullString(row.emp_rec_firstname_th) +
                  ' ' +
                  checkNullString(row.emp_rec_lastname_th) +
                  '<br>' +
                  checkNullString(row.emp_rec_phone_number) +
                  ''
                );
              },
            },
          ],
        });
      } else {
        if (data.message) {
          this.error_message = data.message;
        } else {
          this.error_message = 'no content';
        }
      }
    } catch (error) {
      this.error_message = error.message.message;
    }
  }

  public getDatetime(date) {
    var datetime = '';
    if (date) {
      try {
        const format1 = 'YYYY-MM-DD HH:mm:ss';
        var d = new Date(date);
        var dd = d.getDate();
        var mm = d.getMonth() + 1;
        var yy = d.getFullYear();
        var hh = d.getHours().toString();
        if (parseInt(hh) < 10) {
          hh = '0' + hh;
        }
        var m = d.getMinutes().toString();
        if (parseInt(m) < 10) {
          m = '0' + m;
        }
        datetime = dd + '/' + mm + '/' + yy + '   ' + hh + ':' + m + ' น.';
      } catch (error) {
        console.log(error);
        datetime = 'no date time';
      }
    }
    return datetime;
  }

  public checkNullString(string) {
    var resturnString = '';
    if (string) {
      resturnString = string;
    }
    return resturnString;
  }
}
