import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import http from '../../http-common';
import axios, { AxiosResponse, AxiosError } from 'axios';

@Injectable()
export class GetEmpRepository {
  constructor() {}

  getEmpinfoUrl = "/checkemp";

  getEmpInfoByEmpCode = async (token: any , body: any) => {
    
    return new Promise<any>(async (resolve, reject) => {
      try {

        const dataResp = await http
          .post(environment.URL + this.getEmpinfoUrl, body,  { headers: {"Authorization" : `Bearer ${token}`} } )
        .then((resp:AxiosResponse)=>resp.data)
          .catch((err: AxiosError) => {
            if (err.response!) {
              return reject({
                code: err.response.status,
                message: err.response.data,
              });
            }
          });
        return resolve(dataResp)
      } catch (err) {
        return reject(err);
      }
    });
  };
}
