<!-- header -->
<div class="container-fluid" style="background-color: #EFEDED;">
    <div class="row justify-content-between">
        <div>
            <img src="../../assets/images/aot_logo_new.png" class="img-fluid" style="max-height: 75px;margin-left: 10px;"/>
        </div>
        <div class="align-items-center" style="display: flex;">
            <div id="" style="margin-right: 10px;">{{userid}}</div>
            <div id="firstname" style="margin-right: 10px;">{{firstname}}</div>
            <div is="lastname" style="margin-right: 10px;">{{lastname}}</div>
            <div style="margin-right: 10px;" (click)="openModal('modal_logout')">
                <img src="../../assets/images/icon_logout.png" class="img-fluid" style="max-height: 40px;"/>
            </div>
        </div>
    </div>
</div>

<!-- menu -->
<div class="container-fluid">
    <div class="align-items-center" style="display: flex;">
        <img src="../../assets/images/icon_back.png" class="img-fluid" style="max-height: 45px;margin: 20px;" (click)="openRoute('home')"/>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('getcar')">รับรถ </button>
        <button class="btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;margin: 10px;" >คืนรถ </button>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('reportgetreturncar')">รายงานรับคืนรถ </button>
    </div>
</div>

<!--<div class="container-fluid">
    <div [hidden]="!error_message" style="margin-right: 10px;color: #EFEDED; background-color: #F06464; padding: 10px;">
        <i class="fa fa-times" aria-hidden="true"></i>
        {{error_message}}</div>
</div>-->

<div class="container-fluid" [hidden]="!error_message" style="margin-right: 10px;color: #EFEDED; background-color: #F06464; padding: 10px;margin-bottom: 10px;">
    ทำรายการไม่สำเร็จ เนื่องจาก {{error_message}}
</div>
<div class="container-fluid" [hidden]="!success_message" style="margin-right: 10px;color: #EFEDED; background-color:#04AA6D; padding: 10px;margin-bottom: 10px;">
    {{success_message}}
</div>
<!-- content -->
<div class="container-fluid" style="height: 80%;">
    <div class="justify-content-center align-items-start" style="display: flex;min-height: 100%;">
        <!-- detail -->
        <div class="col">
            <div>
                <div style="border-radius: 10px;background-color: #F4F4F4;">
                    <div class="align-items-center" style="background-color: #25C6E4;color: white;border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 10px;">ข้อมูลรถ</div>
                    <div class="container-fluid" style="padding: 10px;text-align: center;">
                        <div class="align-items-center" *ngIf="!car_info_data && !submit_car">กรุณาแสกน QRcode</div>
                        <div class="align-items-center" *ngIf="!car_info_data && submit_car">
                            {{error_message_car}}
                          </div>
                        <div *ngIf="car_info_data" class="container-fluid" style="display: flex;">
                            <div class="col" style="background-color: #E3E4E4;border-radius: 10px;">
                                <img src="../../assets/images/car_img_holder.png" class="img-fluid"/>
                            </div>
                            <div class="col" style="padding: 0;">
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">รุ่นรถ :</div>
                                    <div class="col">{{car_info_data.vehModel.veh_model_name}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">หมายเลขรถ :</div>
                                    <div class="col">{{car_info_data.vehInfo.veh_code}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">ทะเบียนรถ :</div>
                                    <div class="col">{{car_info_data.vehInfo.license_plate_no}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 50px;"></div>
            <div>
                <div style="border-radius: 10px;background-color: #F4F4F4;">
                    <div class="align-items-center" style="background-color: #25C6E4;color: white;border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 10px;">ข้อมูลพนักงาน</div>
                    <div class="container-fluid" style="padding: 10px;text-align: center;">
                        <div class="align-items-center" *ngIf="!emp_info_data && !submit_car" class="align-items-center">กรุณาแสกน QRcode</div>
                        <div class="align-items-center" *ngIf="!emp_info_data && submit_car">
                            {{error_message_car}}
                          </div>
                        <div *ngIf="emp_info_data" class="container-fluid" style="display: flex;">
                            <div class="col" style="background-color: #E3E4E4;border-radius: 10px;">
                                <img src="../../assets/images/emp_img_holder.png" class="img-fluid"/>
                            </div>
                            <div class="col" style="padding: 0;">
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">รหัสพนักงาน :</div>
                                    <div class="col">{{emp_info_data.emp_id}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">ชื่อ - นามสกุล :</div>
                                    <div class="col">{{emp_info_data.firstname_th}} {{emp_info_data.lastname_th}}</div>
                                </div>
                                <div class="col" style="display: flex;padding: 0px;padding-top: 10px;">
                                    <div class="col">เบอร์โทรศัพท์ :</div>
                                    <div class="col">{{emp_info_data.phone_number}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- input -->
        <div class="col">
            <div *ngIf="lang == 'en'" class="col">
                <div *ngIf="user_agent == 'mobile'" class="container-fluid">
                    <div *ngIf="!camera_init" style="text-align: center;" (click)="initCamera()">
                        <img src="../../assets/images/icon_scan_qr.png" class="img-fluid" />
                        <div>แตะเพื่อแสกน QRcode</div>
                    </div>
                    <div [hidden]="!camera_init">
                        <div>QRCode Scanner</div>
                        <div class="" id="loadingMessage">🎥 Unable to access video stream (please make sure you have a webcam enabled)</div>
                        <div class="row justify-content-center">
                            <canvas id="canvas" hidden style="max-width: 250px;max-height: 250px;"></canvas>
                        </div>
                        <div id="output" hidden>
                        <div id="outputMessage">No QR code detected.</div>
                        <div hidden>
                            <b>Data:</b> <span id="outputData"></span>
                        </div>
                        </div>
                        <audio id="beepsound" controls hidden>
                        <source src="../../assets/sound/scanner-beeps-barcode.mp3" type="audio/mpeg">
                        Your browser does not support the audio tag.
                        </audio>  
                    </div>
                </div>
            </div>
            <div class="col" style="display: flex;padding: 0px;margin-top: 10px;">
                <div class="col-4">
                    <div>หมายเลขรถ : </div>
                </div>
                <div class="col-8" style="padding: 0px;">
                    <input type="text" class="form-control" name="car_no" id="car_no" (keyup.enter)="onKeyUpEvent($event , 'km_end')" (input)="valueChange($event , '')" (focus)="onFocus()"/>
                </div>
                <button type="button"  *ngIf="car_info_data" (click)="clearCarInfo()" class="btn btn-danger">x</button>
            </div>
            <div class="col" style="display: flex;padding: 0px;margin-top: 10px;">
                <div class="col-4">
                    <p>ก.ม.สิ้นสุด : </p>
                </div>
                <div class="col-8" style="padding: 0px;">
                    <input type="text" class="form-control" name="km_end" id="km_end" (keyup.enter)="onKeyUpEvent($event , '')" (input)="valueChange($event , '')" (focus)="onFocus()"/>
                </div>
            </div>
            <div class="col" style="display: flex;padding: 0px;margin-top: 10px;">
                <div class="col-4"></div>
                <button [disabled]="!car_info_data || !km_end_text" class="col-8 btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;" (click)="returnCar()">คืนรถ </button>
            </div>
        </div>
    </div>
</div>

<!-- logout dialog  -->
<jw-modal id="modal_logout">
    <div class="container">
      <h1>Dispatch Website</h1>
      <div style="height: 1px;background-color: black;"></div>
      <div style="min-height: 100px;">ออกจากระบบ</div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <button class="col btn btn-lg btn-block" (click)="signOut();" style="background-color: #F0F0F7;color: #0B499C;border-radius: 0px;border-bottom-left-radius: 10px;">
                ตกลง
            </button>
            <button class="col btn btn-lg btn-block" (click)="closeModal('modal_logout');" style="background-color: #EDDBDB;color: #F06464;;border-radius: 0px;border-bottom-right-radius: 10px;margin: 0px;">
              ยกเลิก
            </button>
        </div>
    </div>
</jw-modal>
