import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenService } from '../services/authen/authen.service';
import { ModalService } from '../_modal/modal.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  title = 'ข้อความ';
  isLoading = false;
  isSubmitted = false;
  error = '';
  modal_message = '';

  // loginForm = new FormGroup({
  //   username: new FormControl('', [Validators.required]),
  //   password: new FormControl('', [Validators.required]),
  // });
  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authenService: AuthenService,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: [
        '',
        [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]+$')],
      ],
      password: ['', Validators.required],
    });
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    const userName = this.loginForm.value.username;
    const passWord = this.loginForm.value.password;
    this.authenService
      .userLogin(userName, passWord)
      .pipe(first())
      .subscribe({
        next: () => {
          this.router.navigate(['home'], {
            queryParams: { loggedin: 'success' },
          });
        },
        error: (error) => {
          this.openModal('custom-modal-1', error);
          // this.error = error;
          // this.isLoading = false;
          //
        },
      });
  }

  onKeyUpEvent(event: any, nextfocus): void {
    if (nextfocus == 'login') {
      this.onSubmit();
    } else {
      document.getElementById(nextfocus).focus();
    }
  }

  openModal(id: string, message: string) {
    this.modal_message = message;
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
