<!-- header -->
<div class="container-fluid" style="background-color: #EFEDED;">
    <div class="row justify-content-between">
        <div>
            <img src="../../assets/images/aot_logo_new.png" class="img-fluid" style="max-height: 75px;margin-left: 10px;"/>
        </div>
        <div class="align-items-center" style="display: flex;">
            <div id="" style="margin-right: 10px;">{{userid}}</div>
            <div id="firstname" style="margin-right: 10px;">{{firstname}}</div>
            <div is="lastname" style="margin-right: 10px;">{{lastname}}</div>
            <div style="margin-right: 10px;" (click)="openModal('modal_logout')">
                <img src="../../assets/images/icon_logout.png" class="img-fluid" style="max-height: 40px;"/>
            </div>
        </div>
    </div>
</div>

<!-- menu -->
<div class="container-fluid">
    <div class="align-items-center" style="display: flex;">
        <img src="../../assets/images/icon_back.png" class="img-fluid" style="max-height: 45px;margin: 20px;" (click)="openRoute('home')"/>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('getcar')">รับรถ </button>
        <button class="btn btn-lg" style="background-color: #B9B9B9;color: white;border-radius: 25px;margin: 10px;" (click)="openRoute('returncar')">คืนรถ </button>
        <button class="btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;margin: 10px;" >รายงานรับคืนรถ </button>
    </div>
</div>

<div class="container-fluid">
    <div class="container-fluid" style="display: flex;">
        <div class="col-4 align-items-center" style="display: flex;padding: 0px;">
            <div class="col-4" style="padding-left: 0px;">พนักงานขับรถ : </div>
            <div class="col-8">
                <input type="text" class="form-control" name="emp_no" id="emp_no"/>
            </div>
        </div>
        <div class="col-4 align-items-center" style="display: flex;padding: 0px">
            <div class="col-4" style="padding-left: 0px;">ทะเบียนรถ : </div>
            <div class="col-8">
                <input type="text" class="form-control" name="car_no" id="car_no" />
            </div>
        </div>
        <div class="col">
            <button class="col-6 btn btn-lg" style="background-color: #3C90FF;color: white;border-radius: 25px;" (click)="getReport()">ค้นหา</button>
        </div>
    </div>
</div>
    
<div class="container-fluid">
    <div class="col" style="margin-top: 10px;">แสดงรายการที่รับรถ 24 ชั่วโมง</div>
</div>

<div class="container-fluid">
    <div class="col" style="margin-top: 20px;"> 
        <table id="weeklytable" class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions">
            <thead>
            <tr>
                <th>No.</th>
                <th>รหัส</th>
                <th>พนักงานขับรถ</th>
                <th>รุ่นรถ</th>
                <th>ทะเบียน</th>
                <th>กม.เริ่มต้น</th>
                <th>เวลารับรถ/พนักงาน Dispatcher</th>
                <th>กม.สิ้นสุด</th>
                <th>เวลาคืนรถ/พนักงาน Dispatcher</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of reportData">
                <!-- <td>{{ data.dispatch_id }}</td>
                <td>{{ data.dispatch_id }}</td>
                <td>{{ data.driver_firstname_th }} {{ data.driver_lastname_th }} {{ data.driver_phone_number }}</td>
                <td>{{ data.veh_model_name }}</td>
                <td>{{ data.veh_info_license_plate_no }}</td>
                <td>{{ data.dispatch_km }}</td>
                <td>{{ data.dispatch_time }} / {{ data.emp_send_firstname_th }} {{ data.emp_send_lastname_th }}  {{ data.emp_send_phone_number }} </td>
                <td>{{ data.return_km }}</td>
                <td>{{ data.return_time }} / {{ data.emp_rec_firstname_th }} {{ data.emp_rec_lastname_th }}  {{ data.emp_rec_phone_number }} </td> -->
            </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- logout dialog  -->
<jw-modal id="modal_logout">
    <div class="container">
      <h1>Dispatch Website</h1>
      <div style="height: 1px;background-color: black;"></div>
      <div style="min-height: 100px;">ออกจากระบบ</div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <button class="col btn btn-lg btn-block" (click)="signOut();" style="background-color: #F0F0F7;color: #0B499C;border-radius: 0px;border-bottom-left-radius: 10px;">
                ตกลง
            </button>
            <button class="col btn btn-lg btn-block" (click)="closeModal('modal_logout');" style="background-color: #EDDBDB;color: #F06464;;border-radius: 0px;border-bottom-right-radius: 10px;margin: 0px;">
              ยกเลิก
            </button>
        </div>
    </div>
</jw-modal>
