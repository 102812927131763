import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuard } from './services/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { FirstComponent } from './first/first.component';
import { SecondComponent } from './second/second.component';
import { HomeComponent } from './home/home.component';
import { GetcarComponent } from './getcar/getcar.component';
import { ReturncarComponent } from './returncar/returncar.component';
import { ReportgetreturncarComponent } from './reportgetreturncar/reportgetreturncar.component';
import { GetjobComponent } from './getjob/getjob.component';
import { FinishjobComponent } from './finishjob/finishjob.component';
import { ReportlssComponent } from './reportlss/reportlss.component';
import { ReportvrpComponent } from './reportvrp/reportvrp.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TestRepository } from './repository/test';
import { LoginRepository } from './repository/login';
import { GetcarRepository } from './repository/getcar';
import { GetEmpRepository } from './repository/getemp';
import { ReturnCarRepository } from './repository/returncar';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { ModalModule } from './_modal';
import { ReportvipComponent } from './reportvip/reportvip.component';
import { ReportmaintainComponent } from './reportmaintain/reportmaintain.component';
import { GetJobRepository } from './repository/getjob';
import { ReportRepository } from './repository/report';
import { ErrorInterceptor } from './_helper/error/error.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FirstComponent,
    SecondComponent,
    HomeComponent,
    GetcarComponent,
    ReturncarComponent,
    ReportgetreturncarComponent,
    GetjobComponent,
    FinishjobComponent,
    ReportlssComponent,
    ReportvrpComponent,
    ReportvipComponent,
    ReportmaintainComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    DataTablesModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    ModalModule,
  ],
  providers: [
    AuthGuard,
    TestRepository,
    LoginRepository,
    GetcarRepository,
    GetEmpRepository,
    ReturnCarRepository,
    GetJobRepository,
    ReportRepository,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
