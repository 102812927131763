<div class="container-fluid">
    <div class="row justify-content-between">
        <div class="align-items-center" style="display: flex;">
            <div class="back_logo" style="margin-left: 5px;" (click)="openRoute('home')"></div>
            <button style="margin-left: 5px;" (click)="openRoute('getjob')">รับงาน </button>
            <button style="margin-left: 5px;" (click)="openRoute('finishjob')">เสร็จงาน </button>
            <button style="margin-left: 5px;" (click)="openRoute('reportlss')">รายงาน LSS </button>
            <button style="margin-left: 5px;" disabled>รายงาน VRP </button>
        </div>
        <div class="align-items-center" style="display: flex;">
            <div style="margin-right: 10px;">userid </div>
            <div style="margin-right: 10px;">name </div>
            <div style="margin-right: 10px;">sname </div>
            <div class="sign_out_logo" style="margin-right: 10px;"></div>
        </div>
    </div>
</div>

<div style="height: 1px;background-color: black;"></div>

<div class="col align-items-end" style="display: flex;padding: 0px;">
    <div class="col-8" style="padding: 0px;">
        <div class="col" style="display: flex;padding: 0px;">
            <div class="col-5" style="display: flex;padding: 0px;">
                <div class="col-4">พนักงานขับรถ</div>
                <div class="col-8">
                    <input type="text" class="input_text" name="emp_no" />
                </div>
            </div>
            <div class="col-5" style="display: flex;padding: 0px;">
                <div class="col-4">ทะเบียนรถ</div>
                <div class="col-8">
                    <input type="text" class="input_text" name="car_no" />
                </div>
            </div>
            <div>
                <select name="cars_model" id="cars_model" style="width: 150px;">
                    <option value="def">ทุกรุ่นรถ</option>
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="opel">Opel</option>
                    <option value="audi">Audi</option>
                </select>
            </div>
        </div>

        <div class="col" style="display: flex;padding: 0px;margin-top: 20px;">
            <div class="col-5" style="display: flex;padding: 0px;">
                <div class="col-4">QR code ใบงาน</div>
                <div class="col-8">
                    <input type="text" class="input_text" name="qr_no" />
                </div>
            </div>
            <div class="col-5" style="display: flex;padding: 0px;">
                <div class="col-4">รหัสใบงาน</div>
                <div class="col-8">
                    <input type="text" class="input_text" name="job_no" />
                </div>
            </div>
            <div>
                <select name="job_status" id="job_status" style="width: 150px;">
                    <option value="job_def">สถานะใบงาน</option>
                  </select>
            </div>
        </div>
    </div>

    <div class="col-4" style="display: flex;">
        <div style="width: 50px;"></div>
        <input type="submit" class="ct_button" value="ค้นหา" style="width: 150px;"/>
    </div>
</div>

<div class="col">
    <p>แสดงรายการที่รับรถ 24 ชั่วโมง</p>
</div>

<div class="col">
    <table class="table table-bordered table-striped table-hover" datatable [dtOptions]="dtOptions">
        <thead>
        <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Phone number</th>
            <th>Address</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of allUsers">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.phone }}</td>
            <td>{{ user.address }}</td>
        </tr>
        </tbody>
    </table>
</div>

