import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportRepository } from '../repository/report';
import { AutologoutService } from '../services/autologout/autologout.service';
import { ModalService } from '../_modal';

@Component({
  selector: 'app-reportmaintain',
  templateUrl: './reportmaintain.component.html',
  styleUrls: ['./reportmaintain.component.css'],
})
export class ReportmaintainComponent implements OnInit {
  lang: any = 'en';
  reportData: any;
  vehInfo: any;

  dtOptions: DataTables.Settings = {};

  userid: any;
  firstname: any;
  lastname: any;
  accessToken: any;

  error_message = '';

  perpage = 20;
  totalpage = 0;
  nextpage = null;
  prepage = null;
  page = 1;
  allpage =  [];
  maxpage = 10;
  lastpage = null;
  constructor(
    private router: Router,
    private modalService: ModalService,
    private reportRepo: ReportRepository,
    private autoLogoutService: AutologoutService
  ) {}

  ngOnInit(): void {
    this.dtOptions = {
      searching: false,
      ordering: false,
      pageLength: this.perpage,
      processing: true,
      lengthChange: false,
      paging: false,
      info: false,
      scrollX: true,
    };

    $('#weeklytable').DataTable().destroy();

    try {
      var currentUser = JSON.parse(localStorage.getItem('user'));

      console.info(currentUser);

      this.userid = currentUser.account.user_id;
      this.firstname = currentUser.profile.firstname_th;
      this.lastname = currentUser.profile.lastname_th;

      this.accessToken = currentUser.accessToken;
    } catch (error) {}
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getReport();
      this.getVehInfo();
    }, 0);
  }

  public openRoute(event) {
    this.router.navigate([event]);
  }

  public signOut() {
    localStorage.setItem('user', null);
    this.openRoute('login');
  }

  public openModal(id: string) {
    this.modalService.open(id);
  }

  public closeModal(id: string) {
    this.modalService.close(id);
  }

  async getReport() {
    this.reportData = null;
    // vehId,
    // vehLicense,
    // vehType,
    // workType,
    // typeName,
    // jobPickupStart,
    // jobPickUpEnd,
    // jobCode,
    // centerId,
    // vehCode,

    let carbrand = (document.getElementById('cars_model') as HTMLInputElement)
      .value;
    let vehType = '';
    if (this.vehInfo) {
      for (let i = 0; i < this.vehInfo.length; i++) {
        let d = this.vehInfo[i];
        if (carbrand == d.veh_brand_code) {
          vehType = d.veh_brand_id;
        }
      }
    }
    let body = {
      driverInfo : (document.getElementById("emp_no") as HTMLInputElement).value,
      vehLicense: (document.getElementById('car_no') as HTMLInputElement).value,
      jobCode: (document.getElementById('qr_no') as HTMLInputElement).value,
      vehType: vehType,
      jobStatus : (document.getElementById("job_status") as HTMLInputElement).value,
      page:this.page
    };
    console.log('body ' + body);
    this.allpage =  [];
    this.nextpage = null;
    this.prepage = null;
    this.lastpage = null;
    try {
      var data: any = await this.reportRepo.getReportMA(this.accessToken, body);
      this.error_message = '';
      $('#weeklytable').DataTable().destroy().clear;

      if (data.code == 200 || data.code == 1) {
        this.reportData = data.data;
        this.totalpage = Math.ceil(data.count / this.perpage);
         /**********************/
         var sp;
         if(this.page < this.maxpage){
             sp = 1;
         }else if(this.page  >= (this.totalpage - Math.floor(this.maxpage / 2)) ){
             sp = this.totalpage - this.maxpage + 1;
         }else if(this.page  >= this.maxpage){
             sp = this.page   - Math.floor(this.maxpage/2);
         }
 
         if(this.totalpage < this.maxpage){
            for(let i = sp; i <= this.totalpage;i++){
              this.allpage.push(i);
            }
         }else{
            for(let i = sp; i <= (sp + (this.maxpage -1));i++){
              this.allpage.push(i);
            } 
         }
         
         
         this.nextpage = this.page < this.totalpage ? this.page + 1 : null;
         this.prepage =  this.page > 1 ? this.page - 1 : null;
         this.lastpage =  this.page < (this.totalpage -  Math.floor(this.maxpage / 2)) ? this.totalpage : null;
         /**********************/
         //console.log(this.totalpage);
         //console.log(this.nextpage);
         //console.log( this.prepage );
        console.log('data ' + this.reportData);

        //$('#weeklytable').DataTable().destroy().clear;

        var index = ((this.page * this.perpage) - this.perpage);
        var getDatetime = (date) => this.getDatetime(date);
        var checkNullString = (string) => this.checkNullString(string);
        var checkStatus = (any) => this.checkStatus(any);
        var t = $('#weeklytable').DataTable({
          searching: false,
          ordering: false,
          pageLength: this.perpage,
          processing: true,
          lengthChange: false,
          scrollX: true,
          paging: false,
          info: false,
          data: this.reportData,
          columns: [
            {
              title: 'No.',
              data: null,
              render: function (data, type, row) {
                index = index + 1;
                return index.toString();
              },
            },

            {
              title: 'เวลาที่รับงาน/พนักงาน Dispatcher',
              data: null,
              render: function (data, type, row) {
                return (
                  getDatetime(row.create_datetime) +
                  '<br>' +
                  checkNullString(row.createByDetail.firstname_th) +
                  ' ' +
                  checkNullString(row.createByDetail.lastname_th) +
                  '<br>' +
                  checkNullString(row.emp_send_phone_number) +
                  ''
                );
              },
            },
            {
              title: 'เวลาที่เสร็จงาน/พนักงาน Dispatcher',
              data: null,
              render: function (data, type, row) {
                if (row.booking_status) {
                  // 0 = รอเริ่มงาน,\n1=คนขับกดเริ่มงาน , 2=ออกเดินทาง , 3=ถึงอู๋ ,\n8=ถึงเขตสุวรรณภูมิ , 9=คนขับกดจบงาน=ถึงCP
                  if (row.booking_status < 9) {
                    return '';
                  } else {
                    return (
                      getDatetime(row.update_datetime) +
                      '<br>' +
                      checkNullString(row.createByDetail.firstname_th) +
                      ' ' +
                      checkNullString(row.createByDetail.lastname_th) +
                      '<br>' +
                      checkNullString(row.emp_send_phone_number) +
                      ''
                    );
                  }
                } else {
                  return '';
                }
              },
            },

            // {title: 'สถานะปัจจุบัน', data: 'booking_status'},
            {
              title: 'สถานะปัจจุบัน',
              data: null,
              render: function (data, type, row) {
                return checkStatus(row.booking_status);
              },
            },

            {
              title: 'รหัสพนักงาน',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.createByDetail.emp_id);
              },
            },
            {
              title: 'พนักงานขับรถ',
              data: null,
              render: function (data, type, row) {
                return '';
              },
            },

            {
              title: 'รุ่นรถ',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.vehDetail.veh_model_name);
              },
            },

            {
              title: 'หมายเลขรถ',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.vehDetail.veh_code);
              },
            },

            {
              title: 'ทะเบียน',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.vehDetail.license_plate_no);
              },
            },

            { title: 'QR Code ใบงาน', data: 'booking_code' },

            { title: 'Job No.', data: 'booking_code' },

            {
              title: 'ประเภทงาน',
              data: null,
              render: function (data, type, row) {
                if (row.typeNameDetail) {
                  return checkNullString(row.typeNameDetail.ma_type_name);
                } else {
                  return '';
                }
              },
            },

            {
              title: 'Pickup Datetime',
              data: null,
              render: function (data, type, row) {
                return getDatetime(row.centerDetail.ma_center_create_datetime);
              },
            },
            // {title: 'ต้นทาง', data: null ,
            //   render: function ( data, type, row ) {
            //     return "";
            //   }
            // },
            {
              title: 'สถานที่',
              data: null,
              render: function (data, type, row) {
                return checkNullString(row.centerDetail.ma_center_address);
              },
            },
          ],
        });
      } else {
        this.totalpage = 0;
        this.nextpage = null;
        this.prepage = null;
        this.lastpage = null;

        console.log('error ');
        if (data.message) {
          this.error_message = data.message;
        } else {
          this.error_message = 'no content';
        }
      }
    } catch (error) {
      this.totalpage = 0;
      this.nextpage = null;
      this.prepage = null;
      this.lastpage = null;

      this.allpage =  [];
      this.error_message = error.message.message;
    }
  }
  public getDatetime(date) {
    var datetime = '';
    if (date) {
      try {
        const format1 = 'YYYY-MM-DD HH:mm:ss';
        var d = new Date(date);
        var dd = d.getDate();
        var mm = d.getMonth() + 1;
        var yy = d.getFullYear();
        var hh = d.getHours().toString();
        if (parseInt(hh) < 10) {
          hh = '0' + hh;
        }
        var m = d.getMinutes().toString();
        if (parseInt(m) < 10) {
          m = '0' + m;
        }
        datetime = dd + '/' + mm + '/' + yy + '   ' + hh + ':' + m + ' น.';
      } catch (error) {
        console.log(error);
        datetime = 'no date time';
      }
    }
    return datetime;
  }

  public checkNullString(string) {
    var resturnString = '';
    if (string) {
      resturnString = string;
    }
    return resturnString;
  }

  async getVehInfo() {
    this.reportData = null;
    let body = {};
    console.log('body ' + body);
    try {
      var data: any = await this.reportRepo.getVehInfo(this.accessToken, body);
      this.error_message = '';
      if (data.code == 200) {
        this.vehInfo = data.data.vehInfo;
      } else {
        if (data.message) {
          this.error_message = data.message;
        } else {
          this.error_message = 'no content';
        }
      }
    } catch (error) {
      this.error_message = error.message.message;
    }
  }

  public checkStatus(status: any) {
    let status_string = '';
    let color_string = '#F36D96';
    // 0 = รอเริ่มงาน,\n1=คนขับกดเริ่มงาน , 2=ออกเดินทาง , 3=ถึงอู๋ ,\n8=ถึงเขตสุวรรณภูมิ , 9=คนขับกดจบงาน=ถึงCP
    try {
      if (status == 0) {
        status_string = 'รอเริ่มงาน';
        color_string = '#F36D96';
      } else if (status == 1) {
        status_string = 'เริ่มงาน';
        color_string = '#9A9A9A';
      } else if (status == 2) {
        status_string = 'ออกเดินทาง';
        color_string = '#72266E';
      } else if (status == 3) {
        status_string = 'ถึงอู่';
        color_string = '#2759A4';
      } else if (status == 8) {
        status_string = 'ถึงเขตสุวรรณภูมิ';
        color_string = '#2759A4';
      } else if (status == 9) {
        status_string = 'ถึงCP';
        color_string = '#00A1F7';
      }
    } catch (error) {}
    if (status_string != '') {
      return (
        "<button style='border-radius: 2px;background-color: " +
        color_string +
        ';' +
        'border: none;' +
        'color: white;' +
        'padding: 5px;' +
        'text-align: center;' +
        'text-decoration: none;' +
        'display: inline-block;' +
        'font-size: 16px;' +
        'margin: 4px 2px;' +
        "cursor: pointer;'>" +
        status_string +
        '</button>'
      );
    } else {
      return '';
    }
  }


  setpage(page){
    this.page = page;
    this.getReport();
    console.log(page);
  }


}
