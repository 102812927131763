import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import http from '../../http-common';
import axios, { AxiosResponse, AxiosError } from 'axios';

@Injectable()
export class ReportRepository {
  constructor() {}

  reportGetReturnCarUrl = "/getreportdispatch";
  reportVipUrl = "/vip/report";
  reportMAUrl = "/ma/report";
  reportLssUrl = "/lss/report";
  vechUrl = "/getvehbrand";

  getReportGetReturnCar = async (token: any , body: any) => {
    
    return new Promise<any>(async (resolve, reject) => {
      try {

        const dataResp = await http
          .post(environment.URL + this.reportGetReturnCarUrl, body,  { headers: {"Authorization" : `Bearer ${token}`} } )
        .then((resp:AxiosResponse)=>resp.data)
          .catch((err: AxiosError) => {
            if (err.response!) {
              return reject({
                code: err.response.status,
                message: err.response.data,
              });
            }
          });
        return resolve(dataResp)
      } catch (err) {
        return reject(err);
      }
    });
  };

  getReportVip = async (token: any , body: any) => {
    
    return new Promise<any>(async (resolve, reject) => {
      try {

        let search = "?page="+body.page;

        if(body.jobStatus){
          if(search==""){
            search = search+"?jobStatus="+body.jobStatus
          }else{
            search = search+"&jobStatus="+body.jobStatus
          }
        }

        if(body.driverInfo){
          if(search==""){
            search = search+"?driverInfo="+body.driverInfo
          }else{
            search = search+"&driverInfo="+body.driverInfo
          }
        }
        if(body.plateLicense){
          if(search==""){
            search = search+"?plateLicense="+body.plateLicense
          }else{
            search = search+"&plateLicense="+body.plateLicense
          }
        }
        if(body.qrCode){
          if(search==""){
            search = search+"?qrCode="+body.qrCode
          }else{
            search = search+"&qrCode="+body.qrCode
          }
        }
        if(body.vehModel){
          if(search==""){
            search = search+"?vehModel="+body.vehModel
          }else{
            search = search+"&vehModel="+body.vehModel
          }
        }

        let url = this.reportVipUrl
        if(search!=""){
          url = url+search
        }

        const dataResp = await http
          .get(environment.URL + url,  { headers: {"Authorization" : `Bearer ${token}`} } )
        .then((resp:AxiosResponse)=>resp.data)
          .catch((err: AxiosError) => {
            if (err.response!) {
              return reject({
                code: err.response.status,
                message: err.response.data,
              });
            }
          });
        return resolve(dataResp)
      } catch (err) {
        return reject(err);
      }
    });
  };
  
  getReportMA = async (token: any , body: any) => {

    // vehId,
    // vehLicense,
    // vehType,
    // workType,
    // typeName,
    // jobPickupStart,
    // jobPickUpEnd,
    // jobCode,
    // centerId,
    // vehCode,
    
    return new Promise<any>(async (resolve, reject) => {
      try {

        let search = "?page="+body.page;


        if(body.jobStatus){
          if(search==""){
            search = search+"?jobStatus="+body.jobStatus
          }else{
            search = search+"&jobStatus="+body.jobStatus
          }
        }

        if(body.driverInfo){
          if(search==""){
            search = search+"?driverInfo="+body.driverInfo
          }else{
            search = search+"&driverInfo="+body.driverInfo
          }
        }

        if(body.vehLicense){
          if(search==""){
            search = search+"?vehLicense="+body.vehLicense
          }else{
            search = search+"&vehLicense="+body.vehLicense
          }
        }
        if(body.jobCode){
          if(search==""){
            search = search+"?jobCode="+body.jobCode
          }else{
            search = search+"&jobCode="+body.jobCode
          }
        }
        if(body.vehType){
          if(search==""){
            search = search+"?vehType="+body.vehType
          }else{
            search = search+"&vehType="+body.vehType
          }
        }
        let url = this.reportMAUrl
        if(search!=""){
          url = url+search
        }
        console.info(url)
        const dataResp = await http
          .get(environment.URL + url,  { headers: {"Authorization" : `Bearer ${token}`} } )
        .then((resp:AxiosResponse)=>resp.data)
          .catch((err: AxiosError) => {
            if (err.response!) {
              return reject({
                code: err.response.status,
                message: err.response.data,
              });
            }
          });
        return resolve(dataResp)
      } catch (err) {
        return reject(err);
      }
    });
  };

  getReportLss = async (token: any , body: any) => {

    //page=1
    //&driverInfo
    //&plateLicense=ฌข-3274
    //&qrCode
    ///&vehModel=Toyota
    //&jobStatus=0
    
    return new Promise<any>(async (resolve, reject) => {
      try {

        let search = "?page="+body.page;

        if(body.jobStatus){
          if(search==""){
            search = search+"?jobStatus="+body.jobStatus
          }else{
            search = search+"&jobStatus="+body.jobStatus
          }
        }

        if(body.driverInfo){
          if(search==""){
            search = search+"?driverInfo="+body.driverInfo
          }else{
            search = search+"&driverInfo="+body.driverInfo
          }
        }
        if(body.plateLicense){
          if(search==""){
            search = search+"?plateLicense="+body.plateLicense
          }else{
            search = search+"&plateLicense="+body.plateLicense
          }
        }
        if(body.qrCode){
          if(search==""){
            search = search+"?qrCode="+body.qrCode
          }else{
            search = search+"&qrCode="+body.qrCode
          }
        }
        if(body.vehModel){
          if(search==""){
            search = search+"?vehModel="+body.vehModel
          }else{
            search = search+"&vehModel="+body.vehModel
          }
        }

        let url = this.reportLssUrl
        if(search!=""){
          url = url+search
        }
        console.info(url)
        const dataResp = await http
          .get(environment.URL + url,  { headers: {"Authorization" : `Bearer ${token}`} } )
        .then((resp:AxiosResponse)=>resp.data)
          .catch((err: AxiosError) => {
            if (err.response!) {
              return reject({
                code: err.response.status,
                message: err.response.data,
              });
            }
          });
        return resolve(dataResp)
      } catch (err) {
        return reject(err);
      }
    });
  };

  getVehInfo = async (token: any , body: any) => {
    
    return new Promise<any>(async (resolve, reject) => {
      try {
        const dataResp = await http
          .get(environment.URL + this.vechUrl,  { headers: {"Authorization" : `Bearer ${token}`} } )
        .then((resp:AxiosResponse)=>resp.data)
          .catch((err: AxiosError) => {
            if (err.response!) {
              return reject({
                code: err.response.status,
                message: err.response.data,
              });
            }
          });
        return resolve(dataResp)
      } catch (err) {
        return reject(err);
      }
    });
  };
}
