<div class="container-fluid" style="background-color: #efeded">
  <div class="row justify-content-between">
    <div>
      <img
        src="../../assets/images/aot_logo_new.png"
        class="img-fluid"
        style="max-height: 75px; margin-left: 10px"
      />
    </div>
    <div class="align-items-center" style="display: flex">
      <div id="" style="margin-right: 10px">{{ userid }}</div>
      <div id="firstname" style="margin-right: 10px">{{ firstname }}</div>
      <div is="lastname" style="margin-right: 10px">{{ lastname }}</div>
      <div style="margin-right: 10px" (click)="openModal('modal_logout')">
        <img
          src="../../assets/images/icon_logout.png"
          class="img-fluid"
          style="max-height: 40px"
        />
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" style="height: 90%">
  <div
    class="justify-content-center align-items-center"
    style="display: flex; height: 100%"
  >
    <div
      class="action_box"
      style="
        background-color: #25c6e4;
        border: 3px solid #25c6e4;
        border-radius: 10px;
      "
    >
      <div (click)="openRoute('getcar')">
        <div>
          <img
            src="../../assets/images/icon_get_return_car.png"
            class="img-fluid"
          />
        </div>
        <p style="color: white; text-align: center; margin-top: 20px">
          รับรถ-คืนรถ{{ data_message }}
        </p>
      </div>
    </div>
    <div style="width: 50px"></div>
    <div
      class="action_box"
      style="
        background-color: #0a489b;
        border: 3px solid #0a489b;
        border-radius: 10px;
      "
    >
      <div (click)="openRoute('getjob')">
        <div>
          <img
            src="../../assets/images/icon_get_return_job.png"
            class="img-fluid"
          />
        </div>
        <p style="color: white; text-align: center; margin-top: 20px">
          รับงาน-เสร็จงาน
        </p>
      </div>
    </div>
  </div>
</div>

<jw-modal id="modal_logout">
  <div class="container">
    <h1>Dispatch Website</h1>
    <div style="height: 1px; background-color: black"></div>
    <div style="min-height: 100px">ออกจากระบบ</div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <button
        class="col btn btn-lg btn-block"
        (click)="signOut()"
        style="
          background-color: #f0f0f7;
          color: #0b499c;
          border-radius: 0px;
          border-bottom-left-radius: 10px;
        "
      >
        ตกลง
      </button>
      <button
        class="col btn btn-lg btn-block"
        (click)="closeModal('modal_logout')"
        style="
          background-color: #eddbdb;
          color: #f06464;
          border-radius: 0px;
          border-bottom-right-radius: 10px;
          margin: 0px;
        "
      >
        ยกเลิก
      </button>
    </div>
  </div>
</jw-modal>
