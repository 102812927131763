import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenService } from '../services/authen/authen.service';
// import { environment } from 'src/environments/environment';

// import http from '../../http-common';
// import axios, { AxiosResponse, AxiosError } from 'axios';

@Injectable()
export class LoginRepository {
  isLoading = false;
  isSubmit = false;
  errorMsg = '';
  constructor(private authenServices: AuthenService, private router: Router) {}

  // loginUrl = '/login';

  // login = async (body: any) => {
  //   return new Promise<any>(async (resolve, reject) => {
  //     try {
  //       const dataResp = await http
  //         .post(environment.URL + this.loginUrl, body, {})
  //         .then((resp: AxiosResponse) => resp.data)
  //         .catch((err: AxiosError) => {
  //           if (err.response!) {
  //             return reject({
  //               code: err.response.status,
  //               message: err.response.data,
  //             });
  //           }
  //         });
  //       return resolve(dataResp);
  //     } catch (err) {
  //       return reject(err);
  //     }
  //   });
  // };
}
