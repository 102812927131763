import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
// import axios from '../services/axios-client';

import http from '../../http-common';
import axios, { AxiosResponse, AxiosError } from 'axios';

@Injectable()
export class TestRepository {
  constructor() {}

  async testFetch() {
    const headers = {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJDWkFjY291bnRJZCI6NzM5LCJGTmFtZSI6IkFkaXNvcm4iLCJMTmFtZSI6IlNvbmJ1dCIsIkVtYWlsIjoiamlubjExMjNAZ21haWwuY29tIiwiQ2l0eUlkIjo2LCJDaXR5TmFtZSI6IuC5gOC4l-C4qOC4muC4suC4peC5gOC4oeC4t-C4reC4h-C4o-C5ieC4reC4ouC5gOC4reC5h-C4lCIsIkJnQ29sb3IiOiIjMzZmN2Y3IiwiQ2l0aXplbklkIjo3NjEsIkZiSWQiOiIzMzg2MjI3MDA4MDU5MjgxIiwiSXNGYiI6MSwiQ3JlYXRlRGF0ZVRpbWUiOiIyMDIwLTA1LTEzVDAyOjQ4OjA0LjAwMFoiLCJUb2tlbiI6IjQxMDQ4NzczMzY1MDAyODI5NTMyNjgzNyIsIkNpdGl6ZW5JbWciOm51bGwsIkNpdGl6ZW5UeXBlIjpudWxsLCJTdG9yZUlkIjpudWxsLCJGaXJlYmFzZUNsaWVudElkIjoiZGRTcnhidEFNaUE6QVBBOTFiR1RWaEx6Mkp5c1Bzd3NCbVIyWGZRdEdMTElSUnJrbDJIeWtsb0VWZUN3QmlaWW1HSUlLenphd0I5RWVGVmJhdUE1Z0FETk1mYUpQbVd2MW5VLTNfT3dhdWpZVngxdE4yazVmX2hTdjNtNUtxZXQxT3p1SXJHTThHNk5QWjJoUFpKQkZxakoiLCJBcnRpY2xlTmV3c0NhdElkIjoxOSwiQXdhcmROZXdzQ2F0SWQiOjE3LCJZb3V0dWJlTGlzdCI6IlBMWGVqaHhwSVNCcXZ2a0RiLUNleFNnUGNZWm54V05ZcFciLCJMb2dvVXJsIjoiaHR0cHM6Ly9yZXRzbS1tYXJrZXQtYXBpLWNpdGl6ZW4uYXp1cmV3ZWJzaXRlcy5uZXQvZ2V0aW1nY2l0eT9jb2RlPXJvaWV0LnBuZyIsImlhdCI6MTYyOTQ1NTI4M30.uB7EU-m_t4N0eXttiAnHYBJ8MAt5LhXqpusvauZjIq9j4WV3HuNg73TOrN7Y9dP-ZCZjWqs-tVwWbEEnN-eu3g',
    };
    var body = {
      city_id: '6',
      news_cat_id: null,
      token: '410487733650028295326837',
      limit: '10',
      offset: '0',
    };
    const resp = await axios.post(environment.URL, body, { headers });
    console.log(resp.data);
    return resp.data;
  }

  // Example arrow function
  testFetchFunction = async (data: any) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJDWkFjY291bnRJZCI6NzM5LCJGTmFtZSI6IkFkaXNvcm4iLCJMTmFtZSI6IlNvbmJ1dCIsIkVtYWlsIjoiamlubjExMjNAZ21haWwuY29tIiwiQ2l0eUlkIjo2LCJDaXR5TmFtZSI6IuC5gOC4l-C4qOC4muC4suC4peC5gOC4oeC4t-C4reC4h-C4o-C5ieC4reC4ouC5gOC4reC5h-C4lCIsIkJnQ29sb3IiOiIjMzZmN2Y3IiwiQ2l0aXplbklkIjo3NjEsIkZiSWQiOiIzMzg2MjI3MDA4MDU5MjgxIiwiSXNGYiI6MSwiQ3JlYXRlRGF0ZVRpbWUiOiIyMDIwLTA1LTEzVDAyOjQ4OjA0LjAwMFoiLCJUb2tlbiI6IjQxMDQ4NzczMzY1MDAyODI5NTMyNjgzNyIsIkNpdGl6ZW5JbWciOm51bGwsIkNpdGl6ZW5UeXBlIjpudWxsLCJTdG9yZUlkIjpudWxsLCJGaXJlYmFzZUNsaWVudElkIjoiZGRTcnhidEFNaUE6QVBBOTFiR1RWaEx6Mkp5c1Bzd3NCbVIyWGZRdEdMTElSUnJrbDJIeWtsb0VWZUN3QmlaWW1HSUlLenphd0I5RWVGVmJhdUE1Z0FETk1mYUpQbVd2MW5VLTNfT3dhdWpZVngxdE4yazVmX2hTdjNtNUtxZXQxT3p1SXJHTThHNk5QWjJoUFpKQkZxakoiLCJBcnRpY2xlTmV3c0NhdElkIjoxOSwiQXdhcmROZXdzQ2F0SWQiOjE3LCJZb3V0dWJlTGlzdCI6IlBMWGVqaHhwSVNCcXZ2a0RiLUNleFNnUGNZWm54V05ZcFciLCJMb2dvVXJsIjoiaHR0cHM6Ly9yZXRzbS1tYXJrZXQtYXBpLWNpdGl6ZW4uYXp1cmV3ZWJzaXRlcy5uZXQvZ2V0aW1nY2l0eT9jb2RlPXJvaWV0LnBuZyIsImlhdCI6MTYyOTQ1NTI4M30.uB7EU-m_t4N0eXttiAnHYBJ8MAt5LhXqpusvauZjIq9j4WV3HuNg73TOrN7Y9dP-ZCZjWqs-tVwWbEEnN-eu3g',
      };
      var body = {
        city_id: '6',
        news_cat_id: null,
        token: '410487733650028295326837',
        limit: '10',
        offset: '0',
      };
    return new Promise<any>(async (resolve, reject) => {
      try {
        const dataResp = await http
          .post(environment.URL, body, { headers })
        .then((resp:AxiosResponse)=>resp.data)
          .catch((err: AxiosError) => {
            if (err.response!) {
              return reject({
                code: err.response.status,
                message: err.response.data,
              });
            }
          });
        return resolve(dataResp)
      } catch (err) {
        return reject(err);
      }
    });
  };
}
