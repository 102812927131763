import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //Your redirect logic/condition. I use this.

    var accessToken = "";
    // try {
    //   var currentUser = JSON.parse(localStorage.getItem('user'));
    //   accessToken = currentUser.accessToken; 
    // } catch (error) {
    //   accessToken = "";
    // }
    
    if (accessToken == "" || accessToken == null) {
      if(state.url != "/login"){
        this.router.navigate(['login']);
      }
    }else{
      if(state.url == "/login" || state.url == "/"){
        this.router.navigate(['home']);
      }
    }

    console.log('AuthGuard#canActivate called');
    return true;
  }
  
  //Constructor
  constructor(private router: Router) {}
}
