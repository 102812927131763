import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';

import { FirstComponent } from './first/first.component';
import { SecondComponent } from './second/second.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { GetcarComponent } from './getcar/getcar.component';
import { ReturncarComponent } from './returncar/returncar.component';
import { ReportgetreturncarComponent } from './reportgetreturncar/reportgetreturncar.component';
import { GetjobComponent } from './getjob/getjob.component';
import { FinishjobComponent } from './finishjob/finishjob.component';
import { ReportlssComponent } from './reportlss/reportlss.component';
import { ReportvrpComponent } from './reportvrp/reportvrp.component';
import { ReportvipComponent } from './reportvip/reportvip.component';
import { ReportmaintainComponent } from './reportmaintain/reportmaintain.component';

import { AuthGuard } from './services/auth-guard.service';

const routes: Routes = [
  { path: 'first-component', component: FirstComponent },
  { path: 'second-component', component: SecondComponent },
  // {
  //   path: '',
  //   redirectTo: "login",
  //   pathMatch: "full"
  // },
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'getcar',
    component: GetcarComponent,
  },
  {
    path: 'returncar',
    component: ReturncarComponent,
  },
  {
    path: 'reportgetreturncar',
    component: ReportgetreturncarComponent,
  },
  {
    path: 'getjob',
    component: GetjobComponent,
  },
  {
    path: 'finishjob',
    component: FinishjobComponent,
  },
  {
    path: 'reportlss',
    component: ReportlssComponent,
  },
  {
    path: 'reportvrp',
    component: ReportvrpComponent,
  },
  {
    path: 'reportvip',
    component: ReportvipComponent,
  },
  {
    path: 'reportmaintain',
    component: ReportmaintainComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
