import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TestRepository } from '../repository/test';
import { ApiService } from '../services/api.service';
import { AutologoutService } from '../services/autologout/autologout.service';
import { ModalService } from '../_modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  lang: any = 'en';
  data_message: any = '';

  userid: any;
  firstname: any;
  lastname: any;

  constructor(
    private router: Router,
    private testRepo: TestRepository,
    private modalService: ModalService,
    private autoLogoutService: AutologoutService
  ) {}

  ngOnInit(): void {
    //  this.showConfig()
    try {
      var currentUser = JSON.parse(localStorage.getItem('user'));
      this.userid = currentUser.account.user_id;
      this.firstname = currentUser.profile.firstname_th;
      this.lastname = currentUser.profile.lastname_th;
    } catch (error) {}
  }

  ngAfterViewInit() {}

  public openRoute(event) {
    this.router.navigate([event]);
  }

  async showConfig() {
    var data: any = await this.testRepo.testFetchFunction('');
    console.log(data);
    this.data_message = data.message;
  }

  public signOut() {
    localStorage.setItem('user', null);
    this.openRoute('login');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
