<div class="jw-modal container">
    <div class="row">
        <div class="col-3">
        </div>
        <div class="jw-modal-body col-6" style="border-radius: 10px;padding: 0px;">
            <ng-content></ng-content>
        </div>
        <div class="col-3">
        </div>
    </div>
</div>
<div class="jw-modal-background"></div>