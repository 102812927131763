import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jsQR from 'jsqr';
import { GetJobRepository } from '../repository/getjob';
import { AutologoutService } from '../services/autologout/autologout.service';
import { ModalService } from '../_modal';

@Component({
  selector: 'app-finishjob',
  templateUrl: './finishjob.component.html',
  styleUrls: ['./finishjob.component.css'],
})
export class FinishjobComponent implements OnInit {
  lang: any = 'en';
  user_agent = 'user_agent';

  userid: any;
  firstname: any;
  lastname: any;
  accessToken: any;

  emp_info_data: any;
  car_info_data: any;
  job_info_data: any;

  camera_init = false;

  video;
  canvasElement;
  canvas;
  loadingMessage;
  outputContainer;
  outputMessage;
  outputData;
  beepsound;
  outputQrcode;
  TLR;
  TRR;
  BRL;
  BLL;
  code;
  waiting;
  cameraStream;

  qr_no_text: any;

  current_focus: any;

  error_message = '';

  submit_car:boolean = false;

  error_message_car = '';
  error_message_emp = '';
  error_message_job = '';

  success_message = '';

  constructor(
    private router: Router,
    private modalService: ModalService,
    private location: PlatformLocation,
    private getJobRepo: GetJobRepository,
    private autoLogoutService: AutologoutService
  ) {}

  ngOnInit(): void {
    // this.emp_info_data = {}
    // this.car_info_data = {}
    // this.job_info_data = {}

    try {
      var currentUser = JSON.parse(localStorage.getItem('user'));

      console.info(currentUser);

      this.userid = currentUser.account.user_id;
      this.firstname = currentUser.profile.firstname_th;
      this.lastname = currentUser.profile.lastname_th;

      this.accessToken = currentUser.accessToken;
    } catch (error) {}

    var ua = navigator.userAgent;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this.user_agent = 'mobile';
    } else {
      this.user_agent = 'pc';
    }

    this.location.onPopState(() => {
      this.stopCamera();
    });
  }

  public openRoute(event) {
    this.stopCamera();
    this.router.navigate([event]);
  }

  public signOut() {
    localStorage.setItem('user', null);
    this.openRoute('login');
  }

  public openModal(id: string) {
    this.modalService.open(id);
  }

  public closeModal(id: string) {
    this.modalService.close(id);
  }

  public valueChange(event: any, nextfocus) {
    if (document.activeElement.id == 'qr_no') {
      this.qr_no_text = event.target.value;
    }
  }

  public onFocus() {
    this.current_focus = document.activeElement.id;
  }

  public onKeyUpEvent(event: any, nextfocus) {
    if (document.activeElement.id == 'qr_no') {
      this.getQRInfo(event.target.value, nextfocus);
    }
  }

  async finishJob() {
    let body = {
      qrcode: this.qr_no_text,
      jobStatus: '9',
      empid: this.emp_info_data.emp_id,
    };
    try {
      var data: any = await this.getJobRepo.changeJobStatusSuccess(
        this.accessToken,
        body
      );
      this.error_message = '';
      this.success_message = '';

      console.info(data);
      if (data.code == 200) {
        this.success_message = 'ท่านทำรายการสำเร็จแล้วค่ะ';
        setTimeout(()=>{                          
          window.location.reload();
        }, 3000);
        //this.openModal('modal_finishjob_success');
      } else {
        if (data.message) {
          this.error_message = data.message;
        } else {
          this.error_message = 'no content';
        }
      }
    } catch (error) {
      this.error_message = error.message.message;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      document.getElementById('qr_no').focus();
    }, 0);
  }

  public initCamera() {
    this.camera_init = true;
    this.video = document.createElement('video');
    this.canvasElement = document.getElementById('canvas');
    this.canvas = this.canvasElement.getContext('2d');
    this.loadingMessage = document.getElementById('loadingMessage');
    this.outputContainer = document.getElementById('output');
    this.outputMessage = document.getElementById('outputMessage');
    this.outputData = document.getElementById('outputData');
    this.beepsound = document.getElementById('beepsound');
    this.outputQrcode = document.getElementById('outputqrcode');

    var videos = this.video;
    var tick = () => this.tick();
    var setStream = (stream) => this.setStream(stream);
    var render = tick.bind(this);

    // Use facingMode: environment to attemt to get the front camera on phones
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then(function (stream) {
        videos.srcObject = stream;
        setStream(stream);
        videos.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
        videos.play();
        window.requestAnimationFrame(render);
        // setInterval(tick,1000/60) ;
      });
    console.info('focus = ' + this.current_focus.toString());
    document.getElementById(this.current_focus).focus();
  }

  public tick() {
    this.loadingMessage.innerText = '⏳ Loading video...';
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      this.loadingMessage.hidden = true;
      this.canvasElement.hidden = false;
      this.outputContainer.hidden = false;

      this.canvasElement.height = this.video.videoHeight;
      this.canvasElement.width = this.video.videoWidth;
      this.canvas.drawImage(
        this.video,
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );
      if (!this.video.paused) {
        var imageData = this.canvas.getImageData(
          0,
          0,
          this.canvasElement.width,
          this.canvasElement.height
        );
        this.code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });
      }
      if (this.code) {
        this.TLR = this.drawLine(
          this.code.location.topLeftCorner,
          this.code.location.topRightCorner,
          '#FF3B58'
        );
        this.TRR = this.drawLine(
          this.code.location.topRightCorner,
          this.code.location.bottomRightCorner,
          '#FF3B58'
        );
        this.BRL = this.drawLine(
          this.code.location.bottomRightCorner,
          this.code.location.bottomLeftCorner,
          '#FF3B58'
        );
        this.BLL = this.drawLine(
          this.code.location.bottomLeftCorner,
          this.code.location.topLeftCorner,
          '#FF3B58'
        );
        this.outputMessage.hidden = true;
        this.outputData.parentElement.hidden = false;
        this.outputData.innerText = this.code.data;
        if (
          this.code.data != '' &&
          !this.waiting &&
          this.TLR == true &&
          this.TRR == true &&
          this.BRL == true &&
          this.BLL == true
        ) {
          var qrdata = this.code.data;
          if (document.activeElement.id == 'qr_no') {
            setTimeout(() => {
              (document.getElementById('qr_no') as HTMLInputElement).value =
                qrdata;
            }, 0);
            this.qr_no_text = this.code.data;
            this.getQRInfo(this.code.data, '');
          }

          console.log(this.code.data);
          // สามารถส่งค่า code.data ไปทำงานอย่างอื่นๆ ผ่าน ajax ได้
          this.video.pause();
          this.beepsound.play();
          var beepsounds = this.beepsound;
          this.beepsound.onended = function () {
            beepsounds.muted = true;
          };
          var videos = this.video;
          // ให้เริ่มเล่นวิดีโอก่อนล็กน้อย เพื่อล้างค่ารูป qrcod ล่าสุด เป็นการใช้รูปจากกล้องแทน
          setTimeout(function () {
            videos.play();
          }, 4500);
          // ให้รอ 5 วินาทีสำหรับการ สแกนในครั้งจ่อไป
          this.waiting = setTimeout(() => this.reset(), 5000);

          // var active = document.activeElement;
          // var next_id;
          // if(active.id == "car_no"){
          //   //get qr then change if success

          //   //mock
          //   // this.car_no_data = this.code.data;
          //   next_id = "qr_no";
          //   // this.changeFocus(next_id);
          // }else if(active.id == "qr_no"){
          //   //get qr then change if success

          //   //mock
          //   this.qr_no_data = this.code.data;
          //   next_id = "remark";
          //   // this.changeFocus(next_id);
          // }else{
          //   //get qr then submit if success

          //   //mock
          // }
        }
      } else {
        this.outputMessage.hidden = false;
        this.outputData.parentElement.hidden = true;
      }
    }
    // window.requestAnimationFrame(() => this.tick);
    window.requestAnimationFrame(this.tick.bind(this));
    // setInterval(() => this.tick(),1000/60) ;
  }

  public setStream(stream) {
    this.cameraStream = stream;
  }

  public reset() {
    this.TLR, this.TRR, this.BRL, (this.BLL = null);
    this.beepsound.muted = false;
    if (this.waiting) {
      clearTimeout(this.waiting);
      this.waiting = null;
    }
  }

  public drawLine(begin, end, color) {
    this.canvas.beginPath();
    this.canvas.moveTo(begin.x, begin.y);
    this.canvas.lineTo(end.x, end.y);
    this.canvas.lineWidth = 4;
    this.canvas.strokeStyle = color;
    this.canvas.stroke();
    return true;
  }

  public stopCamera() {
    try {
      this.video.pause();
      this.video.src = '';
      this.cameraStream.getTracks().map(function (val) {
        val.stop();
      });
    } catch (error) {}
  }

  async getQRInfo(qrCode: any, nextfocus) {
    this.submit_car = false;
    this.error_message_emp = '';
    this.error_message_car = '';
    this.error_message_job = '';
    this.emp_info_data = null;
    this.car_info_data = null;
    document.getElementById("qr_no").style.borderColor = "#ced4da";

    this.job_info_data = null;
    let body = {
      qrcode: qrCode,
    };
    this.submit_car = true;

    try {
      var data: any = await this.getJobRepo.getQrInfoByQrCodeFin(
        this.accessToken,
        body
      );
      this.error_message = '';
      if (data.code == 200) {
        this.job_info_data = data.data;
       // console.log("xxx",this.job_info_data);
        // document.getElementById(nextfocus).focus()

        try {
          this.emp_info_data = this.job_info_data.driverDetail ? this.job_info_data.driverDetail[0] : null;
          if(!this.emp_info_data){
            this.error_message_emp = 'ไม่พบข้อมูล';
          }

        } catch (error) {
        }

        try {
          if(this.job_info_data.vehInfo && this.job_info_data.vehModel){
            this.car_info_data = {
              vehInfo: this.job_info_data.vehInfo,
              vehModel: this.job_info_data.vehModel,
            };
          }else{
            this.error_message_car = 'ไม่พบข้อมูล';
          }
         
        } catch (error) {
        }
      } else {
        if (data.message) {
          this.error_message_job = data.message;
          this.error_message_emp = data.message;
          this.error_message_car = data.message;
        } else {
          this.error_message_job = 'ไม่พบข้อมูล';
          this.error_message_emp = 'ไม่พบข้อมูล';
          this.error_message_car = 'ไม่พบข้อมูล';
        }
        document.getElementById("qr_no").style.borderColor = "red";
        (document.getElementById('qr_no') as HTMLInputElement).value = '';
        document.getElementById('qr_no').focus();
      }
    } catch (error) {
      this.error_message = error.message.message;
    }
  }



  public clearJobInfo(){
    this.submit_car = false;
    this.car_info_data = null;
    this.job_info_data = null;
    this.emp_info_data = null;


    this.error_message_emp = '';
    this.error_message_car = '';
    this.error_message_job = '';

    this.qr_no_text = null;
    (document.getElementById('qr_no') as HTMLInputElement).value = '';
    document.getElementById('qr_no').focus();

  }

  addHours(numOfHours, date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return date;
  }

  public getDatetime(date) {
    var datetime;
    try {
      const format1 = 'YYYY-MM-DD HH:mm:ss';
      var d = new Date(date);
      d =  this.addHours(7, d);
      var dd = d.getDate();
      var mm = d.getMonth() + 1;
      var yy = d.getFullYear();
      var hh = d.getHours().toString();
      if (parseInt(hh) < 10) {
        hh = '0' + hh;
      }
      var m = d.getMinutes().toString();
      if (parseInt(m) < 10) {
        m = '0' + m;
      }
      datetime = dd + '/' + mm + '/' + yy + '   ' + hh + ':' + m + ' น.';
    } catch (error) {
      console.log(error);
      // datetime = this.job_info_data.sellJob.print_datetime
      datetime = 'no date time';
    }
    return datetime;
  }

  public findOrigin() {
    console.log(this.job_info_data.sellJobDetail);
    var origin;
    try {
      for (let i = 0; i < this.job_info_data.sellJobDetail.length; i++) {
        let data = this.job_info_data.sellJobDetail[i];
        if (this.job_info_data.type == 'lss') {
          if (data.sequence == '1') {
            origin = data.force_destination;
            break;
          }
        } else if (this.job_info_data.type == 'vip') {
          if (data.job_detail_sequence == '1') {
            for (let a = 0; a < this.job_info_data.placeDetail.length; a++) {
              let place = this.job_info_data.placeDetail[a];
              if (data.job_detail_place_id == place.place_id) {
                origin = place.name_th;
                break;
              }
            }
          }
        }
      }
    } catch (error) {}
    return origin;
  }

  public findDest() {
    // 1 = origin , 2 = dropoff , 3 = destination
    var dest;
    try {
      for (let i = 0; i < this.job_info_data.sellJobDetail.length; i++) {
        let data = this.job_info_data.sellJobDetail[i];
        if (this.job_info_data.type == 'lss') {
          if (data.sequence == '2') {
            dest = data.force_destination;
            break;
          }
        } else if (this.job_info_data.type == 'vip') {
          if (data.job_detail_sequence == '2') {
            for (let a = 0; a < this.job_info_data.placeDetail.length; a++) {
              let place = this.job_info_data.placeDetail[a];
              if (data.job_detail_place_id == place.place_id) {
                dest = place.name_th;
                break;
              }
            }
          }
        }
      }
    } catch (error) {}
    return dest;
  }

  public mapLssBookingByType() {
    // การจองนี้เกิดขึ้นจากอะไร 1 = by customer (web sell) 2 = customer fleet(websell) 3 = emp Fleet (backoffice) 4 = emp Retail (backoffice) 5 = emp Call Center(backoffice)
    var type;
    var color;
    if (this.job_info_data.sellBooking.booking_by_type == '1') {
      type = 'Fleet';
      color = '#56399d';
    } else if (this.job_info_data.sellBooking.booking_by_type == '2') {
      type = 'Customer Fleet';
      color = '#56399d';
    } else if (this.job_info_data.sellBooking.booking_by_type == '3') {
      type = 'EMP Fleet';
      color = '#56399d';
    } else if (this.job_info_data.sellBooking.booking_by_type == '4') {
      type = 'Retail';
      color = '#434fa6';
    } else if (this.job_info_data.sellBooking.booking_by_type == '5') {
      type = 'Call Center';
      color = '#5f99e4';
    }

    //vrp ?
    // type = "VRP"
    // color = "#63a38c"

    return {
      type: type,
      color: color,
    };
  }
  public mapLssJobType() {
    var type;
    var color;
    // 1 = to airport , 2 = from airport , 3 = maintenance, 4 = job rental , 5 = etc.
    if (this.job_info_data.sellJob.job_type == '1') {
      type = 'Pickup';
      color = '#1b3574';
    } else if (this.job_info_data.sellBooking.booking_by_type == '2') {
      type = 'Deliver';
      color = '#1b3574';
    } else if (this.job_info_data.sellBooking.booking_by_type == '3') {
      type = 'MA';
      color = '#7f31a0';
    } else if (this.job_info_data.sellBooking.booking_by_type == '4') {
      type = 'Rental';
      color = '#ecbb59'; //hrs
      // color = "#d05e42" //day
    } else if (this.job_info_data.sellBooking.booking_by_type == '5') {
      type = 'etc.';
      color = '#d05e42';
    }
    return {
      type: type,
      color: color,
    };
  }

  public mapVipWorkType() {
    var type;
    var color;
    // 1 = vip , 2 = ma
    if (this.job_info_data.sellBooking.vip_work_type_id == '1') {
      type = 'VIP';
      color = '#b93b2a';
    } else if (this.job_info_data.sellBooking.vip_work_type_id == '2') {
      type = 'MA';
      color = '#7f31a0';
    }
    return {
      type: type,
      color: color,
    };
  }

  public finishJobSuccess() {
    window.location.reload();
  }
}
